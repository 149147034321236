const sizes = {
  big: 1200,
  small: 480
}

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = string => `
    @media (max-width: ${sizes[label] / 16}em) {
      ${string}
    }
  `

  return acc
}, {})

export default media
