import React from 'react'
// import logo from "../images/logos/logo.png"
// import textImg from "../images/logos/worldlinx_Alliance.png"
import Logo, {
  ReactComponent as LogoComponent
} from '../images/logos/logo_full.svg'
// import world from '../images/planes/6a2ea7d7-f8a6-4041-92e2-716a54f1ef13.jpg'
// import world from '../images/planes/c384d8d9-8ebd-4b84-afca-2757681a25b6.jpg'
import world from '../images/world.jpg'
import { Global, css } from '@emotion/core'
import { Link, StaticQuery, graphql } from 'gatsby'
import media from '../utils/MediaQueries'
const pages = ['about', 'benefits', 'references', 'specifications', 'contact']

const Layout = props => {
  return (
    <>
      <Global
        styles={css`
          :root {
            --colors-branding: #376092;
            --colors-branding-lightest: #f7fcff;
            --colors-branding-dark: #0f4461;
            --colors-branding-accent: #4ca1e1;
            --colors-primary-text: #232844;
            /* Font-sizes */
            --font-size-unit: 16px;
            --font-size-xs: calc(0.5 * var(--font-size-unit));
            --font-size-sm: calc(0.75 * var(--font-size-unit));
            --font-size-md: calc(1 * var(--font-size-unit));
            --font-size-lg: calc(1.25 * var(--font-size-unit));
            --font-size-xl: calc(2 * var(--font-size-unit));
            --font-size-xxl: calc(3.25 * var(--font-size-unit));

            /* Spacing */
            --spacing-unit: 1em;
            --spacing-xxs: calc(0.25 * var(--spacing-unit));
            --spacing-xs: calc(0.5 * var(--spacing-unit));
            --spacing-sm: calc(0.75 * var(--spacing-unit));
            --spacing-md: calc(1.25 * var(--spacing-unit));
            --spacing-lg: calc(2 * var(--spacing-unit));
            --spacing-xl: calc(3.25 * var(--spacing-unit));
            --spacing-xxl: calc(5.25 * var(--spacing-unit));
            /* Box shadow */
            --box-shadow-main: 0px 2px 2px #888;
          }
          body,
          html {
            font-family: "Arial Narrow", Arial, sans-serif;
            color: var(--colors-primary-text);
            height: 100vh;
            width: 100vw;
            margin: 0;
            padding: 0;
            background-blend-mode: soft-light;
          }
          body {
            overflow: hidden;
            &:after {
              content: "";
              background-blend-mode: multiply;
              background: url(${world});
              background-color: var(--colors-branding);
              opacity: 0.9;
              overflow: hidden;
              /* filter: blur(3px); */
              top: 0;
              background-size: 102vw;
              left: 0;
              bottom: 0;
              right: 0;
              position: absolute;
              z-index: -1;
            }
          }
          ::-webkit-scrollbar {
            margin-left: 0.2em;
            width: 4px;
            height: 0.3em;
            background: #c6c6c6;
          }

          /* Track */
          ::-webkit-scrollbar-track {
            background: #f5f9fc;
          }

          /* Handle */
          ::-webkit-scrollbar-thumb {
            background: gray;
            cursor: pointer;
          }

          /* Handle on hover */
          ::-webkit-scrollbar-thumb:hover {
            background: #5a5a5a;
          }
        `}
      ></Global>
      <StaticQuery
        query={graphql`
          {
            allFile(
              filter: {
                relativeDirectory: { eq: "logos" }
                name: { eq: "logo" }
              }
            ) {
              edges {
                node {
                  name
                  childImageSharp {
                    fixed {
                      src
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <div
            css={css`
              height: 100vh;
              overflow: hidden;
              width: 100vw;
              max-width: 1200px;
              margin: 0 auto;
              display: grid;
              grid-template-rows: auto auto 1fr 20px;
              background: rgba(255, 255, 255, 0.7);
              box-shadow: var(--box-shadow-main);
            `}
          >
            <Link
              to="/"
              css={css`
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0.5rem 0;
                background-color: white;
                border-bottom: 1px solid var(--colors-branding);
              `}
            >
              <img
                src={Logo}
                css={css`
                  /* height: 8rem; */
                  margin-right: 1.4rem;
                  max-width: 80vw;
                  /* width: 3rem; */
                  /* max-width: 10vw; */
                `}
              ></img>
              {/*
              <img
                src={textImg}
                css={css`
                  height: 3em;
                  max-width: 80vw;
                `}
              ></img> */}
            </Link>
            <nav
              css={css`
                display: flex;
                ${media.small`flex-direction: column;`}
                /* grid-template-columns: repeat(auto-fill, minmax(9rem, 1fr)); */
                background-color: var(--colors-branding) ;
                justify-content: center;
                box-shadow: var(--box-shadow-main);
                /* ${media.small`display:none;`} */
              text-transform:capitalize;
              `}
            >
              {pages.map(el => (
                <Link
                  to={`/${el}`}
                  key={el}
                  activeClassName="link__active"
                  css={css`
                    display: block;
                    box-sizing: border-box;
                    padding: 0.5rem;
                    text-decoration: antiquewhite;
                    font-size: 1.2rem;
                    color: white;
                    border-bottom: 2px solid transparent;
                    &:hover {
                      transition: 2s ease all;
                      border-bottom: 2px solid var(--colors-branding);
                    }
                    &.link__active {
                      border-bottom: 2px solid white;
                    }
                  `}
                >
                  {el}
                </Link>
              ))}
            </nav>
            <div
              {...props}
              css={css`
                overflow: auto;
                padding: var(--spacing-lg);
                ${media.small`padding: var(--spacing-xxs);`}
              `}
            ></div>
            <footer
              css={css`
                text-align: center;
              `}
            >
              <small>
                ©{new Date().getFullYear()} WorldLinx Alliance - Diepestraat 46
                - 2540 Hove - Belgium
              </small>
            </footer>
          </div>
        )}
      />
    </>
  )
}

export default Layout
