import styled from '@emotion/styled'
export const Card = styled('div')`
  background-color: white;
  border-radius: var(--spacing-sm);
  padding: var(--spacing-md);
  box-shadow: var(--box-shadow-main);
`
export const CardGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${({ minCardWidth = '300px' }) => minCardWidth}, 1fr)
  );
  grid-gap: var(--spacing-md);
  margin-bottom: var(--spacing-md);
`
