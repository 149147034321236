import styled from '@emotion/styled'

export const H1 = styled('h1')`
  font-size: 2.2em;
  color: var(--colors-branding);
  font-weight: 100;
  text-transform: uppercase;
  text-shadow: var(--box-shadow-main);
`

export const H2 = styled('h2')`
  color: var(--colors-branding);
  border-bottom: 1px solid var(--colors-branding);
`
